<template>
  <div>
    <!--overview  & tracker--->
    <b-row class="match-height">
      <!--overview--->
      <b-col cols="6">
        <product-overview />
      </b-col>

      <!--tracker--->
      <b-col cols="6">
        <product-tracker />
      </b-col>
    </b-row>

    <b-card>
    <!-- search & new -->
    <b-row>
      <b-col cols="3">
        <b-form-select
          placeholder="合规状态"
          v-model="query_progress"
          :options="progressOptions"
        />
      </b-col>
      <b-col cols="3">
        <b-form-select
          placeholder="产品阶段"
          v-model="query_compliance"
          :options="complianceOptions"
        />
      </b-col>
      <b-col cols="3">
        <b-input-group>
          <b-form-input
            type="text"
            v-model="query_keyword"
            placeholder="产品名称/产品经理"
            @keypress.enter="doSearch"
          />
          <b-input-group-append is-text>
            <feather-icon
              icon="SearchIcon"
              class="cursor-pointer"
              @click="doSearch"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="3" class="text-right">
        <b-button variant="primary" @click="doSearch">
            <feather-icon class="mr-50" icon="SearchIcon" />
            <span class="align-middle">检索</span>
        </b-button>
        <b-button variant="primary" @click="showEditDlg()" class="ml-1">
          <feather-icon class="mr-50" icon="PlusIcon" />
            <span class="align-middle">新增</span>
        </b-button>
      </b-col>
    </b-row>

    <!-- list -->
      <b-row>
        <!--data list--->
        <b-col cols="12">
          <b-table
            ref="listTable"
            :items="products"
            :fields="listTableFields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            striped
            hover
            responsive
            :per-page="listTablePerPage"
            :current-page="listTableCurrentPage"
            class="mt-1"
            show-empty
            empty-text="没有找到符合要求的数据"
          >
            <template #cell(manager)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="data.item.manager.avatar"
                    :text="data.item.manager.name"
                    variant="light-primary"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.manager }}
                </span>
                <small class="text-muted">{{ data.item.contact }}</small>
              </b-media>
            </template>

            <template #cell(actions)="data">
              <div class="text-left text-nowrap">
                <b-button variant="flat-primary" @click="showEditDlg(data.item)">
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span class="align-middle">编辑</span>
                </b-button>
                <b-button
                  variant="flat-danger"
                  @click="onDelete(data.item)"
                  :disabled="data.item.default == 1">
                  <feather-icon icon="DeleteIcon" class="mr-50" />
                  <span class="align-middle">删除</span></b-button>
                <b-button
                  variant="flat-success"
                  class="btn-icon"
                  @click="viewItem(data)"><feather-icon icon="EyeIcon"/>
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="listTableCurrentPage"
          :total-rows="listTableTotalRows"
          :per-page="listTablePerPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <!-- edit -->
    <ProductEdit :item="currentItem" :visible="showEdit" @hidden="onEditHidden"/>
  </b-card>

  <!-- edit -->
  <b-sidebar
      id="sidebar-edit"
      bg-variant="white"
      width="35%"
      right
      backdrop
      shadow
      :title="currentItem.name"
      v-if="currentItem"
      v-model="showEdit"
    >
      <product-edit :obj="currentItem" :toSave="toSave" @close="showEdit = false" @save="onSave"/>

      <template #footer>
        <div class="p-2 border-top text-right">
          <b-button variant="primary" class="mr-1" @click="toSave = true">保存</b-button>
          <b-button variant="secondary" @click="showEdit = false;toSave = false;">取消</b-button
          >
        </div>
      </template>
    </b-sidebar>

  </div>
</template>


<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInvalidFeedback,
  BAvatar,
  BFormCheckbox,
  BMedia,
  BSidebar,
  VBToggle,
} from "bootstrap-vue";
import ProductView from "./ProductView.vue";
import ProductEdit from "./ProductEdit.vue";

import ProductOverview from "./ProductOverview.vue";
import ProductTracker from "./ProductTracker.vue";

// import { avatarText } from '@core/utils/filter'

export default {
  name: "Product",

  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    BMedia,
    BSidebar,
    ProductOverview,
    ProductTracker,
    ProductView,
    ProductEdit,
  },

  data() {
    return {
      queryData: null,
      products: null,

      // select options
      complianceOptions: [
        { value: "", text: "合规状态" },
        "待评估",
        "已评估,待改进",
        "符合GDPR要求",
        "符合CCPA要求",
        "符合PIPL要求",
      ],
      progressOptions: [
        { value: "", text: "产品阶段" },
        "立项规划",
        "开发测试",
        "在线运行",
        "已下线",
      ],

      // query
      query_progress: "",
      query_compliance: "",
      query_keyword: "",

      // list table
      sortBy: "id",
      sortDesc: false,
      listTableFields: [
        { key: "id", label: "编号" },
        //{ key: "entName", label: "公司名称" },
        { key: "name", label: "产品名称" },
        { key: "manager", label: "产品经理" },
        { key: "compliance", label: "合规状态" },
        { key: "progress", label: "产品阶段" },
        { key: "version", label: "当前版本" },
        { key: "actions", label: "操作" },
      ],
      listTablePerPage: 5,
      listTablePageOptions: [5, 15, 30],
      listTableTotalRows: 1,
      listTableCurrentPage: 1,

      // edit
      currentItem: null,
      baseInfoVisible: true,
      adminUser: null,
      showEdit: false,

      selected: [],
      
      productNew: {
        id: 0,
        eid: 0,
        title: "",
        name: "新建产品",
        manager: "",
        compliance: "",
        progress: "",
        version:null,
        status: 1,
      },
      complianceArray: [], // [] <=>","
      attachmentArray: [], // [] <=>","

      toSave: false,
    };
  },

  computed: {
    listTableItems() {
      return this.queryData ? this.queryData.products : [];
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    doSearch() {
      // debugger
      this.$http
        .get("/products")
        .then((res) => {
          // debugger
          this.products = res.data.data.list;
        });
    },

    //
    editItem(data) {
      this.currentItem = JSON.parse(JSON.stringify(data));
      this.showEdit = true;
    },

    preview(item) {
      this.$router.push(`/products/${item.id}`);
      //this.viewItem = item;
      //this.showView = true;
    },
    showEditDlg(item) {
      this.currentItem = item ? item : this.productNew;
      this.showEdit = true;
    },
    onSave(newObj) {
      if (newObj.id) {
        this.$http
          .put("/products/" + newObj.id, newObj)
          .then((res) => {
            this.doSearch();
            this.showEdit = false;
          });
      } else {
        this.$http.post("/products", newObj).then((res) => {
          this.doSearch();
          this.showEdit = false;
        });
      }
    },

    successed(msg) {
      this.$swal({
        title: "成功",
        text: msg,
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "确认",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // this.backToList();
        }
      });
    },

    // 删除产品
    onDelete(data) {
      let name = data.name;
      this.$bvModal
        .msgBoxConfirm(`是否确定删除产品“ ${name}”？`, {
          title: "删除确认",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          console.log(value);
          this.$http.delete(`/products/${data.id}`).then((res) => {
            this.$bvToast.toast(`${name} 删除成功`, {
              title: `操作成功`,
              variant: "success",
              solid: true,
            });
            this.doSearch();
          });
        });
    },

    onEditHidden() {
      this.showEdit = false;
    },
  },
};
</script>


<style scoped>
.match-height>[class*=col] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column
}

.match-height>[class*=col]>.card {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}
</style>