<template>
  <div v-if="product" class="ml-2 mr-1">
   

    <b-col class="my-1"> 产品名称：{{ product.name }} </b-col>

    <b-col class="my-1"> 产品经理：{{ product.manager }} </b-col>

    <b-col class="my-1"> 合规状态：{{ product.compliance }} </b-col>

    <b-col class="my-1"> 产品阶段：{{ product.progress }} </b-col>

    <b-col class="my-1"> 产品版本：{{ product.version }} </b-col>

    
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BIconShieldLock,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckboxGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BLink,
  BProgress,
} from "bootstrap-vue";

import pdf from "vue-pdf";

export default {
  name: "ProductView",

  components: {
    BBadge,
    BCard,
    BIconShieldLock,
    BCol,
    BFormGroup,
    BFormCheckboxGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BRow,
    BLink,
    BProgress,
    pdf,
  },

  data() {
    return {
      product: null,
      aryCompliance: [],

      numPages: 0,
      loadingPdfTask: null,
    };
  },

  beforeCreate() {
    let { id } = this.$route.params;
    this.$http.get("/products/" + id).then((res) => {
      this.product = res.data.data;
      this.aryCompliance = this.product.compliance
        ? this.product.compliance.split(",")
        : [];

        
        if (this.product.displayFile) {
          this.loadingPdfTask = pdf.createLoadingTask(this.product.displayFile);
          this.loadingPdfTask.promise.then((pdf) => {
            this.numPages = pdf.numPages;
          });
        }
    });
  },

  computed: {},

  methods: {},
};
</script>

<style>
</style>